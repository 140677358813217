import { FormikErrors } from 'formik';

import { CloseFlightDto, CloseFlightDtoPersistence } from '../dtos';

export class CloseFlightDtoMapper {
  public static toPersistence(dto: CloseFlightDto): CloseFlightDtoPersistence {
    const depesh = (() => {
      switch (dto.type) {
        case 'without-dispatch':
          return '0';
        case 'with-dispatch':
          return '1';
        case 'all':
          return '2';
        default:
          return '0';
      }
    })();

    return {
      flight_id: dto.id,
      airWaybill: dto.airWaybillNumber,
      depesh,
      limit: dto.packagingLimit,
    };
  }
}

export class CloseFlightErrorsMapper {
  public static toDomain(errors: any): FormikErrors<CloseFlightDto> {
    return {
      id: errors.id?.join('. '),
      airWaybillNumber: errors.airWaybill.join('. '),
      type: errors.depesh?.join('. '),
      packagingLimit: errors.limit?.join('. '),
    };
  }
}
