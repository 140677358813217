import { Fragment, useCallback } from 'react';
import { Button, message, Modal, Space, Tooltip } from 'antd';
import { generatePath, NavLink, useHistory, useParams } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import { PageContent, Details, SuspenseSpin } from '@core/ui';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';
import { useAuth } from '@modules/auth';

import { useCashFlowTransactionById } from '../hooks';
import { IRemoveCashFlowTransactionRepo, RemoveCashFlowTransactionRepoType } from '../repos';

export const CashFlowTransactionDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { go } = useHistory();
  const { publish } = useBus();
  const { can } = useAuth();

  const { data, isLoading } = useCashFlowTransactionById(id);
  const removeCashFlowTransaction = useService<IRemoveCashFlowTransactionRepo>(RemoveCashFlowTransactionRepoType);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeCashFlowTransaction.execute(id);

      if (result.status === 200) {
        publish({ type: '@cashFlow/transactions/remove/succeed', payload: [id] });
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Tranzaksiyanı silməyə əminsinizmi?', onOk });
  }, [id, publish, removeCashFlowTransaction]);

  const renderType = useCallback((value: string) => {
    switch (value) {
      case 'income':
        return 'Mədaxil';
      case 'expense':
        return 'Məxaric';
      default:
        return null;
    }
  }, []);

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (!data) {
    return null;
  }

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>Tranzaksiya - {data.id}</span>
    </Space>
  );

  const extra = [
    <Tooltip key='edit-button' title='Düzəliş et'>
      <NavLink to={generatePath('/@next/cash-flow/transactions/:id/update', { id })}>
        <Button type='link' icon={<Icons.EditOutlined />} />
      </NavLink>
    </Tooltip>,
    <Tooltip key='delete-button' title='Sil'>
      <Button onClick={remove} type='link' danger={true} icon={<Icons.DeleteOutlined />} />
    </Tooltip>,
  ];

  return (
    <PageContent>
      <Details.PageHeader title={title} subTitle={data.executor.name} extra={can('cashbox_operations.cud') ? extra : undefined} />
      <Details.Row>
        <Details.Col xs={24} lg={12}>
          <Details.Card title='Ümumi məlumat'>
            <Details.Descriptions bordered={true} column={1} size='small'>
              <Details.Descriptions.Item label='Kod'>#{data.id}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='İcraçı'>{data.executor.name}</Details.Descriptions.Item>
              {data.isTransfer && (
                <Fragment>
                  <Details.Descriptions.Item label='Əməliyyat'>{renderType(data.type)}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Kateqoriya'>
                    {data.operation.name} - {data.operation.child.name}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Status'>{data.status.name}</Details.Descriptions.Item>
                </Fragment>
              )}
              <Details.Descriptions.Item label='Əməliyyat tarixi'>{data.operatedAt}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        {!data.isTransfer && (
          <Details.Col xs={24} lg={12}>
            <Details.Card title='Xüsusi məlumat'>
              <Details.Descriptions bordered={true} column={1} size='small'>
                <Details.Descriptions.Item label='Hesab'>{data.cashRegister.name}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Məbləğ'>
                  {data.amount} {data.cashRegister.currency.code}
                </Details.Descriptions.Item>
                <Details.Descriptions.Item label='Əməliyyat'>{renderType(data.type)}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Kateqoriya'>
                  {data.operation.name} - {data.operation.child.name}
                </Details.Descriptions.Item>
                <Details.Descriptions.Item label='Status'>{data.status.name}</Details.Descriptions.Item>
              </Details.Descriptions>
            </Details.Card>
          </Details.Col>
        )}
        {data.isTransfer && data.target && (
          <Details.Col xs={24} lg={12}>
            <Details.Row>
              <Details.Col xs={24}>
                <Details.Card title='Kassadan'>
                  <Details.Descriptions bordered={true} column={1} size='small'>
                    <Details.Descriptions.Item label='Hesab'>{data.cashRegister.name}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Məbləğ'>
                      {data.amount} {data.cashRegister.currency.code}
                    </Details.Descriptions.Item>
                  </Details.Descriptions>
                </Details.Card>
              </Details.Col>
              <Details.Col xs={24}>
                <Details.Card title='Kassaya'>
                  <Details.Descriptions bordered={true} column={1} size='small'>
                    <Details.Descriptions.Item label='Hesab'>{data.target.cashRegister.name}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Məbləğ'>
                      {data.target.amount} {data.target.cashRegister.currency.code}
                    </Details.Descriptions.Item>
                  </Details.Descriptions>
                </Details.Card>
              </Details.Col>
            </Details.Row>
          </Details.Col>
        )}
      </Details.Row>
      <Details.Card title='Açıqlama'>{data.description || 'Qeyd olunmayıb'}</Details.Card>
    </PageContent>
  );
};
