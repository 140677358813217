import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { CloseFlightProvider } from '../contexts';
import { CloseFlight } from '../containers';

export const CloseFlightPage = () => {
  const closeModal = useCloseModal();
  const { id, type } = useParams<{ id: string; type: string }>();

  const handleClose = useCallback(() => {
    closeModal(generatePath('/@next/flights/:id', { id }));
  }, [closeModal, id]);

  return (
    <CloseFlightProvider id={parseInt(id)} type={type} onCancel={handleClose} onSucceed={handleClose}>
      <CloseFlight />
    </CloseFlightProvider>
  );
};
