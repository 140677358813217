import { createContext, FC } from 'react';

export type ICloseFlightContext = {
  id: number;
  onSucceed: () => void;
  onCancel: () => void;
  type: string;
};

const defaultValues: ICloseFlightContext = {
  id: 0,
  type: 'with-dispatch',
  onSucceed: () => {},
  onCancel: () => {},
};

export const CloseFlightContext = createContext<ICloseFlightContext>(defaultValues);

export const CloseFlightProvider: FC<Partial<ICloseFlightContext>> = ({
  children,
  type = defaultValues.type,
  id = defaultValues.id,
  onSucceed = defaultValues.onSucceed,
  onCancel = defaultValues.onCancel,
}) => {
  return <CloseFlightContext.Provider value={{ id, type, onSucceed, onCancel }}>{children}</CloseFlightContext.Provider>;
};
