import * as React from 'react';
import { Alert, Form, Input, Button, Col, Dropdown, Menu, Modal, Result, Row, Space, Steps, Table, Tag, Tooltip, List, Typography } from 'antd';
import * as Icons from '@ant-design/icons';
import { AntForm, Details, SuspenseSpin, TagSpace } from '@core/ui';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@modules/auth';
import { CouponTags } from '@modules/coupons/constants';
import { useAddCommercial, useDeclaration } from '../hooks';
import { DeclarationStatusTag } from '../components';
import { Formik } from 'formik';
import { TextField } from '@core/form';
import TrendyolLogo from '../../../../assets/images/trendyol.svg';
import TemuLogo from '../../../../assets/images/temu.svg';
import ReactJson from 'react-json-view';

export const DeclarationDetails: React.FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();
  const { can } = useAuth();

  const {
    returnDec,
    handover,
    canHandover,
    customsStatus,
    customStatusName,
    waybillIsDisabled,
    openEdit,
    openTimeline,
    openOrder,
    orderUrls,
    printWaybill,
    printProformaInvoice,
    cancelDispatch,
    remove,
    data,
    isLoading,
    error,
    removeFromFlight,
    parcelStates,
    trendyolStatus,
    changeTrendyolStatus,
    customsStatusModalOpen,
    openCustomsStatusModal,
    closeCustomsStatusModal,
    customsStatesInfo,
    pinCodeEditing,
    onTogglePinCodeEditing,
    onPinCodeSubmit,
    form,
    customsStatusName,
  } = useDeclaration(id);

  const { initialValues, addCommercialVoen, closeModal, commercialModalOpen, openModal } = useAddCommercial(+id, data);

  const ordersNode = (
    <Dropdown
      disabled={!orderUrls.data?.length}
      overlay={
        <Menu>
          {orderUrls.data?.map((id, index) => (
            <Menu.Item onClick={() => openOrder(id)} key={id}>
              Sifariş #{index + 1}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button type='primary' icon={<Icons.ShoppingCartOutlined />} ghost={true} block={true}>
        Sifarişlər
      </Button>
    </Dropdown>
  );

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='404' title='Xəta baş verdi' subTitle={error} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data.trackCode}</span>
    </Space>
  );

  const tags = (
    <TagSpace size={8}>
      {data.countryId === 1 && <Tag color='red'>Türkiyə</Tag>}
      {data.countryId === 2 && <Tag color='cyan'>Amerika</Tag>}
      <DeclarationStatusTag key='status-tag' id={data.status.id} name={data.status.name} />
      {!!data.isCommercial ? (
        <Tag color='blue' key='commercial-tag'>
          Kommersial bağlama
        </Tag>
      ) : (
        <Tag key='commercial-tag'>Vətəndaş bağlaması</Tag>
      )}
      {data.returned && (
        <Tag color='#e77b8a' key='returned'>
          İadə edilmişdir
        </Tag>
      )}

      {data.trendyol === 1 && <img style={{ width: 80, height: 30 }} src={TrendyolLogo} alt='Trendyol' />}
      {data.trendyol === 2 && <img style={{ width: 60, height: 40 }} src={TemuLogo} alt='Temu' />}
    </TagSpace>
  );
  const extra = [
    <Tooltip key='state-timeline-button' title='Status xəritəsi'>
      <Button type='link' onClick={openTimeline} icon={<Icons.FieldTimeOutlined />} />
    </Tooltip>,
    <Tooltip key='edit-button' title='Düzəliş et'>
      <Button type='link' onClick={openEdit} icon={<Icons.EditOutlined />} />
    </Tooltip>,
    <Tooltip key='delete-button' title='Sil'>
      <Button type='link' disabled={!can('declaration_cancel')} onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
    </Tooltip>,
  ];
  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} subTitle={data.user.name} tags={tags} extra={extra} />
          {!!data.editable && <Alert message='Bu bağlama məlumatları natamam doldurulduğundan gömrüyə göndərilməyib.' type='warning' showIcon />}
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Actions>
            <Details.ActionCol>
              <Button type='primary' disabled={!canHandover} onClick={handover} icon={<Icons.CheckCircleOutlined />} ghost={true} block={true}>
                Təhvil ver
              </Button>
              <Button type='primary' onClick={returnDec} icon={<Icons.RollbackOutlined />} ghost={true} block={true}>
                İadə et
              </Button>
              <Button type='primary' onClick={printWaybill} disabled={waybillIsDisabled} icon={<Icons.PrinterOutlined />} ghost={true} block={true}>
                Yol vərəqəsi
              </Button>
              <Button type='primary' onClick={printProformaInvoice} icon={<Icons.FileMarkdownOutlined />} ghost={true} block={true}>
                Proforma invoice
              </Button>
              <Button type='primary' onClick={removeFromFlight} icon={<Icons.DeleteOutlined />} ghost={true} block={true}>
                Uçuşdan çıxar
              </Button>
              {can('canceldepesh') && (
                <Button type='primary' onClick={cancelDispatch} icon={<Icons.IssuesCloseOutlined />} disabled={data.status.id !== 8} ghost={true} block={true}>
                  Depeşi ləğv et
                </Button>
              )}
              {data.trendyol === 1 && (
                <Dropdown
                  overlay={
                    <Menu>
                      {trendyolStatus.data?.map((elem) => (
                        <Menu.Item key={elem.id} onClick={() => changeTrendyolStatus(elem.id, elem.name)}>
                          {elem.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button type='primary' icon={<Icons.EditFilled />} ghost={true} block={true}>
                    Trendyol Statusunu dəyiş
                  </Button>
                </Dropdown>
              )}

              {data.isCommercial && (
                <Button type='primary' onClick={openModal} icon={<Icons.FileMarkdownOutlined />} ghost={true} block={true}>
                  Kommersial bəyan
                </Button>
              )}
            </Details.ActionCol>
            <Details.ActionCol>{ordersNode}</Details.ActionCol>
          </Details.Actions>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Müştəri kodu'>{data.user.id}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='İzləmə kodu'>#{data.trackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Qlobal izləmə kodu'>{data.globalTrackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Gömrük bəyanı'>{data.customs === 0 ? 'edilməyib' : 'edilib'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='İadə'>{data.returned ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məhsul tipi'>{data.productType.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Mağaza'>{data.shop}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tərkibi'>{data.type === 'liquid' ? 'Maye' : 'Digər'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Miqdarı'>{data.quantity}</Details.Descriptions.Item>
                  {data.voen && <Details.Descriptions.Item label='VÖEN'>{data.voen}</Details.Descriptions.Item>}
                  <Details.Descriptions.Item label='Sənəd'>
                    {data.file ? (
                      <a href={data.file} target='_blank' rel='noreferrer noopener'>
                        Sənədə bax
                      </a>
                    ) : (
                      'Mövcud deyil'
                    )}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
                  {data.causerId && <Details.Descriptions.Item label='Düzəliş edən'>{data.causerId}</Details.Descriptions.Item>}
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Anbar məlumatları'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Səbət'>{data.basket?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Koli'>{data.parcel?.id || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Şkaf (Yerli)'>{data.wardrobeNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yeşik'>
                    {!!data.lastBox ? (
                      <Tooltip title={data.status.id === 10 ? 'Köhnə yeşik' : undefined}>
                        <Tag color={data.status.id === 10 ? 'success' : undefined}>{data.lastBox?.name}</Tag>
                      </Tooltip>
                    ) : (
                      'Qeyd olunmayıb'
                    )}
                  </Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            {!!Object.keys(data?.trendyolLogs || {}).length && (
              <Details.Col xs={24}>
                <Details.Card title='United Məhsul'>
                  <Details.Descriptions bordered={true} column={1} size='small'>
                    <Details.Descriptions.Item label='Məhsul adı'>{data.trendyolLogs?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Kateqoriya'>{data.trendyolLogs?.category || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Maye'>{data.trendyolLogs?.isLiquid ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Qapıda təhvil'>{data.trendyolLogs?.isDoor ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Növ'>{data.trendyolLogs?.type || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Micro'>{data.trendyolLogs?.isMicro ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='İnvoys qiyməti'>
                      {data.trendyolLogs?.invoice.invoicePrice}
                      <a target='_blank' href={data.trendyolLogs?.invoice.invoiceUrl} rel='noreferrer noopener'>
                        {' '}
                        PDF
                      </a>
                    </Details.Descriptions.Item>
                    <Details.Descriptions.Item label='SKU'>{data.trendyolLogs?.products.sku || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Provayder trak kod'>{data.trendyolLogs?.trendyolDeliveryNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Vahidin qiyməti'>{data.trendyolLogs?.unitPrice || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Sayı'>{data.trendyolLogs?.quantity || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='State'>{data.trendyolLogs?.state || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Parcel id'>{data.trendyolLogs?.parcelId || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='UIDs'>{data.trendyolLogs?.uid || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Gömrük vəziyyəti'>{customsStatusName || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  </Details.Descriptions>
                </Details.Card>
              </Details.Col>
            )}
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Qiymətlər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Məhsulun qiyməti'>{data.price ? `${data.price.toFixed(2)}${data.countryId === 1 ? '₺' : '$'}` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çatdırılma qiyməti'>{data.deliveryPrice ? `${data.deliveryPrice.toFixed(2)}$` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Ödənilmə statusu'>{data.paid ? 'Ödənilib' : 'Ödənilməyib'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Kupon'>{data.couponId ? CouponTags[data.couponId] : '-'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Ölçülər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Çəki'>{data.weight ? `${data.weight.toFixed(2)} kq` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Volumn'>{data.trendyolLogs?.volume || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='En'>{data.depth ? `${data.depth.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Uzunluq'>{data.width ? `${data.width.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Hündürlük'>{data.height ? `${data.height.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>

            <Details.Col xs={24}>
              <Details.Card title='Yerləşmə'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  {data.branch && <Details.Descriptions.Item label='Filial'>{data.branch.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>}
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            {data.flight && (
              <Details.Col xs={24}>
                <Details.Card title='Uçuş'>
                  <Details.Descriptions.Item label='Uçuşun adı'>{data?.flight?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Card>
              </Details.Col>
            )}
            {!!Object.keys(data?.trendyolLogs || {}).length && (
              <Details.Col xs={24}>
                <Details.Card title='United Müştəri'>
                  <Details.Descriptions bordered={true} column={1} size='small'>
                    <Details.Descriptions.Item label='Tam adı'>{data.trendyolLogs?.fullName || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='FIN Kod'>
                      {!pinCodeEditing ? (
                        <Space align='center' size={6}>
                          <Typography.Text>{data.trendyolLogs?.pinCode || 'Qeyd olunmayıb'}</Typography.Text>
                          {data.trendyol === 2 && <Button type='text' icon={<Icons.EditOutlined />} onClick={onTogglePinCodeEditing} />}
                        </Space>
                      ) : (
                        data.trendyol === 2 && (
                          <>
                            <Form form={form} onFinish={onPinCodeSubmit} layout='inline' initialValues={{ pincode: data.trendyolLogs?.pinCode }}>
                              <Form.Item name='pincode'>
                                <Input />
                              </Form.Item>
                            </Form>
                            <Typography.Text style={{ fontSize: '12px' }} type='secondary'>
                              FİN kod düzəlişi bir neçə dəqiqə ərzində icra edilir.
                            </Typography.Text>
                          </>
                        )
                      )}
                    </Details.Descriptions.Item>

                    <Details.Descriptions.Item label='Müştəri qeydi'>{data.trendyolLogs?.comment || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Telefon nömrəsi'>{data.trendyolLogs?.phoneNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Yaradılma tarixi'>{data.trendyolLogs?.createdAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Email'>{data.trendyolLogs?.emailAddress || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='ZIP Kod'>{data.trendyolLogs?.zipCode || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Filial kodu'>{data.trendyolLogs?.warehouseId || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Xarici karqo'>{data.trendyolLogs?.domesticCargoCompany || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Şəhər'>{data.trendyolLogs?.city || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='SMS sayı'>{data.trendyolLogs?.smsCount || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='SMS tarix'>{data.trendyolLogs?.smsDate || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Müştəri ünvanı'>{data.trendyolLogs?.shippingAddress || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  </Details.Descriptions>
                </Details.Card>
              </Details.Col>
            )}
            {(data.trendyol === 1 || data.trendyol === 2) && (
              <Details.Col xs={24}>
                <Table size='small' rowKey='_id' pagination={false} dataSource={parcelStates} title={() => <h4>United Status dəyişiklikləri </h4>} bordered={true}>
                  <Table.Column title='Id' key='_id' dataIndex='_id' width={100} />
                  <Table.Column title='Author' key='author' dataIndex='author' />
                  <Table.Column title='State' key='state' dataIndex='state' />
                  <Table.Column title='Comment' key='comment' dataIndex='comment' />
                  <Table.Column title='Created At' key='createdAt' dataIndex='createdAt' />
                </Table>
              </Details.Col>
            )}
          </Details.Row>
        </Details.Col>

        <Details.Col xs={24}>
          <Details.Card title='Açıqlama'>{data.description || 'Qeyd olunmayıb'}</Details.Card>
        </Details.Col>
        {!customsStatus.isLoading && data.trendyol !== 2 && (
          <React.Fragment>
            <Details.Col xs={24} style={{ display: 'flex', marginBottom: 0, justifyContent: 'center', gap: '1rem' }}>
              <Details.SectionTitle>DGK statusu</Details.SectionTitle>
              <Button onClick={openCustomsStatusModal} type='text' icon={<Icons.FileSearchOutlined />} />
            </Details.Col>
            <Details.Col xs={24} style={{ marginTop: 0 }}>
              <Details.SectionCustomStatus>{customStatusName}</Details.SectionCustomStatus>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.StepsWrapper>
                <Steps current={typeof customsStatus.data?.customsStatus === 'number' ? customsStatus.data.customsStatus : -1} responsive={true} progressDot={true}>
                  <Steps.Step title='Əlavə edilib' description='Karqo şirkəti bağlamanı əlavə edib' />
                  <Steps.Step title='Bəyan edilib' description='Müştəri bağlamanı bəyan edib' />
                  <Steps.Step title='Kolilənib' description='Bağlamalar kolilərə yığılıb' />
                  <Steps.Step title='Depesh göndərilib' description='Depesh sorğusu göndərilib' />
                </Steps>
              </Details.StepsWrapper>
            </Details.Col>
          </React.Fragment>
        )}
        {customsStatus.isLoading && (
          <Details.Col xs={24}>
            <SuspenseSpin />
          </Details.Col>
        )}
      </Details.Row>
      <Modal width={800} title='DGK Statusu' visible={customsStatusModalOpen} onCancel={closeCustomsStatusModal} footer={null}>
        <ReactJson src={customsStatus.data?.json || {}} />
        <List
          size='small'
          header={<Typography.Text>Birbaşa gömrükdən gələn cavabdır və status parametrinin izahı aşağıdakı kimidir.</Typography.Text>}
          dataSource={customsStatesInfo}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text type='secondary'>
                {item.id} - {item.label}
              </Typography.Text>
            </List.Item>
          )}
        />
        <p></p>
      </Modal>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
        {({ values, setErrors, handleReset }) => {
          return (
            <Modal visible={commercialModalOpen} onCancel={closeModal} onOk={() => addCommercialVoen(values, setErrors, handleReset)} title='Kommersial bəyan'>
              <AntForm layout='vertical' component='div' size='large'>
                <Row gutter={[24, 0]}>
                  <Col lg={24} md={24}>
                    <TextField name='declarationId' item={{ label: 'Bağlama' }} input={{ placeholder: 'Bağlama kodunu daxil edin...', disabled: true }} />
                  </Col>
                  <Col lg={24} md={24}>
                    <TextField name='awb' item={{ label: 'Avia Qaimə' }} input={{ placeholder: 'Avia Qaimə kodunu daxil edin...' }} />
                  </Col>
                  <Col lg={24} md={24}>
                    <TextField name='voen' item={{ label: 'VÖEN' }} input={{ placeholder: 'VÖEN daxil edin...' }} />
                  </Col>
                </Row>
              </AntForm>
            </Modal>
          );
        }}
      </Formik>
    </Details.Wrapper>
  );
};
